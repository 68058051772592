import React, { useRef, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import CodeEditor from './CodeEditor';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { fpost } from '../../../axios/axios';

const CreateCyPolicy = () => {
    const { collector_name } = useParams();
    const [policyName, setPolicyName] = useState("");
    const [activeTab, setActiveTab] = useState("input");
    const inputJsonRef = useRef(null);
    const filterJsonRef = useRef(null);
    const outputJsonRef = useRef(null);
    const [workers, setWorkers] = useState(1);
    const [batchSize, setBatchSize] = useState(1);
    const [inputCheckFlag, setInputCheckFlag] = useState(false);    
    const [filterCheckFlag, setFilterCheckFlag] = useState(false);
    const [outputCheckFlag, setOutputCheckFlag] = useState(false);
    const [type, setType] = useState("persisted");
    const [maxByte, setMaxByte] = useState(0);
    const [pageCapacity, setPageCapacity] = useState(0);
    const [policy, setPolicy] = useState(JSON.stringify({}));
    const navigate = useNavigate();
    const handleChange = (e) => {
        setPolicy(e.target.value);
    };
    const convertToBase64 = (json) => {
        try {
            return btoa(unescape(encodeURIComponent(json)));
        } catch (error) {
            console.error("Failed to convert JSON to Base64", error);
            return null;
        }
    }
    const checkJson = (json) => {
        if (json.error) {
            return false;
        }
        return true;
    }
    const apiBaseUrl = localStorage.getItem("apiBaseUrl")

    const CreatePolicy = () => {  
        fpost(`${apiBaseUrl}/logstash/configs/create_pipeline_t?collector_name=${collector_name}`,{
            pipeline_name: policyName,
            conf: policy
        }).then((res)=>{
            console.log("Policy Created",res)
            toast.success("Policy Created")
            navigate("/settings/managed-ingestion", { replace: true });

        }).catch((err)=>{
            console.log("Error",)
        })
     }
     const handleSave = (e) => {
        e.stopPropagation();
            CreatePolicy();

    };
    return (
        <div
            className="col-12 p-4  bg-white rounded-3"
        >
            <div className="d-flex justify-content-between mb-3">
                <h5 className="mb-0">Create Policy</h5>
                <button className="btn btn-primary" type="button" onClick={(e)=>handleSave(e)}>
                    Save
                </button>
            </div>
            <form style={{
                maxHeight: "80vh",
                overflowY: "auto"
            }} >
                {/* User Name */}
                {/* <div className="d-flex flex-column gap-2"> */}
                <div className="form-group border rounded-3 p-4 mb-3">
                    <div className="d-flex flex-row gap-2 mb-4">
                        <div className="form-group col-3">
                            <label className="font-weight-bold" htmlFor="userName">
                                Policy Name
                            </label>
                            <input
                                type="text"
                                id="policyName"
                                name="policyName"
                                className="form-control mt-2"
                                value={policyName}
                                onChange={(e) => setPolicyName(e.target.value)}
                                required
                            />
                        </div>

                    </div>
                    <Tabs
                        defaultActiveKey="input"
                        id="uncontrolled-tab-example"
                        className="mb-4"
                        activeKey={activeTab}
                        onSelect={(e) => setActiveTab(e)}
                    >
                        <Tab
                            eventKey="input"
                            // title="Process"
                            title={
                                <React.Fragment>
                                    Policy
                                </React.Fragment>
                            }
                        >
                            <textarea
                                className="form-control"
                                rows="20"
                                value={policy}
                                onChange={handleChange}
                            />                        </Tab>

                       
                    </Tabs>
                    
                </div>

            </form>
            <ToastContainer />
        </div>
    );
};

export default CreateCyPolicy;