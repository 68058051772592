import React, { useRef, useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { fget, fpost } from '../../../axios/axios';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill CSS

const EditCyPolicy = () => {
    const { collector_name, pipeline_name } = useParams();
    const [policyName, setPolicyName] = useState("");
    const [activeTab, setActiveTab] = useState("input");
    const inputJsonRef = useRef(null);
    const filterJsonRef = useRef(null);
    const outputJsonRef = useRef(null);
    const [workers, setWorkers] = useState(1);
    const [batchSize, setBatchSize] = useState(1);
    const [inputCheckFlag, setInputCheckFlag] = useState(false);
    const [filterCheckFlag, setFilterCheckFlag] = useState(false);
    const [outputCheckFlag, setOutputCheckFlag] = useState(false);
    const [type, setType] = useState("persisted");
    const [maxByte, setMaxByte] = useState(0);
    const [pageCapacity, setPageCapacity] = useState(0);
    const [policy, setPolicy] = useState("");
    
    const apiBaseUrl = localStorage.getItem("apiBaseUrl");

    const showDetails = () => {
        fget(`${apiBaseUrl}/logstash/configs/transporter-config?pipeline_name=${pipeline_name}&collector_name=${collector_name}`).then(({ data }) => {
            setPolicyName(data.name);
            setPolicy(JSON.stringify(data.conf, null, 2)); // Convert JSON to string with indentation
            console.log("Data", data);   
        }).catch((err) => {
            console.log("Error", err);
        });
    };

    useEffect(() => {
        showDetails();
    }, []);

    const handleChange = (e) => {
        setPolicy(e.target.value);
    };

    const convertToBase64 = (json) => {
        try {
            return btoa(unescape(encodeURIComponent(json)));
        } catch (error) {
            console.error("Failed to convert JSON to Base64", error);
            return null;
        }
    };

    const checkJson = (json) => {
        if (json.error) {
            return false;
        }
        return true;
    };

    const CreatePolicy = () => {
        fpost(`${apiBaseUrl}/logstash/configs/create_pipeline_t?collector_name=${collector_name}`, {
            pipeline_name: policyName,
            conf: JSON.parse(policy), // Parse JSON string back to object
        }).then((res) => {
            console.log("Policy Created", res);
        }).catch((err) => {
            console.log("Error", err);
        });
    };

    const handleSave = (e) => {
        e.stopPropagation();
        CreatePolicy();
    };

    return (
        <div className="col-12 p-4 bg-white rounded-3">
            <div className="d-flex justify-content-between mb-3">
                <h5 className="mb-0">Edit Policy</h5>
                <button className="btn btn-primary" onClick={(e) => handleSave(e)}>
                    Save
                </button>
            </div>
            <form style={{ maxHeight: "80vh", overflowY: "auto" }}>
                <div className="form-group border rounded-3 p-4 mb-3">
                    <div className="d-flex flex-row gap-2 mb-4">
                        <div className="form-group col-3">
                            <label className="font-weight-bold" htmlFor="userName">
                                Policy Name
                            </label>
                            <input
                                type="text"
                                id="policyName"
                                name="policyName"
                                className="form-control mt-2"
                                value={policyName}
                                onChange={(e) => setPolicyName(e.target.value)}
                                required
                            />
                        </div>
                    </div>
                    <Tabs
                        defaultActiveKey="input"
                        id="uncontrolled-tab-example"
                        className="mb-4"
                        activeKey={activeTab}
                        onSelect={(e) => setActiveTab(e)}
                    >
                        <Tab
                            eventKey="input"
                            title={<React.Fragment>Policy</React.Fragment>}
                        >
                            <textarea
                                className="form-control"
                                rows="20"
                                value={policy}
                                onChange={handleChange}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </form>
            <ToastContainer />
        </div>
    );
};

export default EditCyPolicy;