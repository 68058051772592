import React, { useEffect, useState, useRef } from 'react'
import { getAccoundCounts, getAccoundDatas, getActiveDirectoryTable, getAuthUserChart, getCountsAD, getEventCount, getFileActivity, getGroupManagementAction, getUserManagementAction } from '../../../../Methods/InvestigationMethods'
import CustomToolTip from '../../../../components/ToolTip/CustomToolTip';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Chart } from "react-google-charts";
import NoDataFound from '../../../../components/NoDataFount/NoDataFound';
import { Bar, Doughnut, getElementAtEvent } from "react-chartjs-2";
import { Button, OverlayTrigger, Tab, Table, Tabs } from 'react-bootstrap';
import { Tooltip as ToolTp } from 'react-bootstrap';

import TableLoader from '../../../../components/Loader/TableLoader';
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../../../Utils/Utils'
import styles from '../../Cykit/CykitInvestigation.styles';
import BarGraph from '../../../../components/Charts/BarGraph';
import { GridFour, Monitor, SquaresFour, Table as TableIcon } from 'phosphor-react';
import AddFilter from '../../../../components/AddFilter/AddFilter';
import useOnclickOutside from 'react-cool-onclickoutside';
import { fpostDl } from '../../../../axios/axios';
import FilterList from '../../../../components/FilterList/FilterList';
import InlineFilter from '../../../../components/InlineFilter/InlineFilter';
import { ArrowsOutSimple } from "phosphor-react";
import ExpandedDocument from '../../../../components/ExpandedDocument/ExpandedDocument';
import Pagination from '../../../../components/Pagination/Pagination';
import PlainCardLoader from '../../../../components/Loader/PlainCardLoader';
import CykitINVExport from './Export';
import TableViewMore from '../../../../components/ViewMoreBtn/TableViewMore';
import moment from 'moment';

// ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);
const datalakeURL = localStorage.getItem("DATALAKE_URL");

const CyActiveDirectory = () => {

    // pagination
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [currentPageCount, setCurrentPageCount] = useState(perPage);
    const [totalPageCount, setTotalPageCount] = useState()
    const [doc, setDoc] = useState(null)
    const [filter, setFilter] = useState([]);
    const [dateFilter, setDateFilter] = useState({
        from: moment().subtract(1, "hour")?.format("yyyy-MM-DDTHH:mm"),
        to: moment().format("yyyy-MM-DDTHH:mm"),
        f: moment().subtract(1, "hour")?.format("yyyy-MM-DDTHH:mm"),
        e: moment().format("yyyy-MM-DDTHH:mm"),
        range: 60
    });

    const [showAddFilter, setShowAddFilter] = useState(false);

    // manage column
    const [showManageColumn, setShowManageColumn] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);

    const [manageDatas, setManageDatas] = useState([
        { text: "Host Name", value: "host.name", isSelected: true },
        { text: "Event Action", value: "event.action", isSelected: true },
        { text: "Event Code", value: "event.code", isSelected: true },
        { text: "Subject Account", value: "winlog.event_data.SubjectUserName", isSelected: false },
        { text: "Target Account", value: "winlog.event_data.TargetUserName", isSelected: true },
        { text: "Share Name", value: "winlog.event_data.ShareName", isSelected: false },
        { text: "Target File", value: "winlog.event_data.RelativeTargetName", isSelected: false },
        { text: "Message", value: "message", isSelected: true },

    ])

    useEffect(() => {

        let storedData = localStorage.getItem("ManageColumn.investigate.cykit.ad")

        if (storedData) {
            setManageDatas(JSON.parse(storedData))
        } else {
            setManageDatas(manageDatas)
        }
    }, [manageDatas])

    const ref = useOnclickOutside(() => {
        setShowAddFilter(false);
        setShowManageColumn(false);
    });

    // tab
    const [isTableTabSelected, setIsTableTabSelected] = useState(false);



    // loader
    const [loadTableData, setLoadTableData] = useState(true)

    const [authFailLoader, setAuthFailLoader] = useState(true)
    const [authSuccessLoader, setAuthSuccessLoader] = useState(true)
    const [accCreatedLoader, setAccCreatedLoader] = useState(true)
    const [accDeletedLoader, setAccDeletedLoader] = useState(true)
    const [grpManagementActionLoader, setGrpManagementActionLoader] = useState(true)
    const [fileAddedLoader, setFileAddedLoader] = useState(true)
    const [fileAccessedLoader, setFileAccessedLoader] = useState(true)
    const [fileModifiedLoader, setFileModifiedLoader] = useState(true)
    const [fileDeletedLoader, setFileDeletedLoader] = useState(true)

    const [userCount, setUserCount] = useState()
    const [hostCount, setHostCount] = useState()
    const [eventCount, setEventCount] = useState()
    const [accountCreatedCount, setAccountCreatedCount] = useState()
    const [accountDeletedCount, setAccountDeletedCount] = useState()

    // chart
    const [authFailLabel, setAuthFailLabel] = useState([])
    const [authFailData, setAuthFailData] = useState([])

    const [authSuccessLabel, setAuthSuccessLabel] = useState([])
    const [authSuccessData, setAuthSuccessData] = useState([])

    const [userManagementLabel, setUserManagementLabel] = useState([])
    const [userManagementData, setUserManagementData] = useState([])

    const [groupManagementLabel, setGroupManagementLabel] = useState([])
    const [groupManagementData, setGroupManagementData] = useState([])


    const [accoundCreatedData, setAccoundCreatedData] = useState([])
    const [accoundDeletedData, setAccoundDeletedData] = useState([])
    const DoughnutGroupchartRef = useRef();
    const DoughnutCreatedchartRef = useRef();
    const DoughnutDeletedchartRef = useRef();
    // table data
    const [activeDirectoryTableData, setActiveDirectoryTableData] = useState([])


    // dougnut
    const [Labels, setLabels] = useState([]);
    const [dataChart, setDataChart] = useState([]);

    const [accoundCreatedDataLabels, setAccoundCreatedDataLabels] = useState([]);
    const [accoundCreatedDataChart, setAccoundCreatedDataChart] = useState([]);

    const [accoundDeletedDataLabels, setAccoundDeletedDataLabels] = useState([]);
    const [accoundDeletedDataChart, setAccoundDeletedDataChart] = useState([]);
    let count = [];
    let count2;
    const module = 'investigation'
    const tab = 'cykit-ad'
    const [showInlineFilter, setShowInlineFilter] = useState({
        show: false,
        index: 0,
    });

    const [expclickedNode, setExpClickedNode] = useState("")
    const [expclose, setExpClose] = useState(false)

    // file share
    const [fileAccessedLabel, setFileAccessedLabel] = useState([])
    const [fileAccessedData, setFileAccessedData] = useState([])
    const [fileAddedLabel, setFileAddedLabel] = useState([])
    const [fileAddedData, setFileAddedData] = useState([])
    const [fileModifiedLabel, setFileModifiedLabel] = useState([])
    const [fileModifiedData, setFileModifiedData] = useState([])
    const [fileDeletedLabel, setFileDeletedLabel] = useState([])
    const [fileDeletedData, setFileDeletedData] = useState([])
    const LogonTypes = {
        "1": {
            "Description": "Interactive",
            "Explanation": "This logon type indicates a user logged on interactively to a computer. For example, a user logging on to their own workstation would generate this type of logon."
        },
        "2": {
            "Description": "Network",
            "Explanation": "This logon type indicates a user logged on to the computer using a network connection. For example, accessing a shared folder on another computer or server."
        },
        "3": {
            "Description": "Batch",
            "Explanation": "This logon type indicates a batch job (scheduled task) has started. For example, a task running under a scheduled job."
        },
        "4": {
            "Description": "Service",
            "Explanation": "This logon type indicates a service has started. For example, a service that logs on with a service account."
        },
        "5": {
            "Description": "Unlock",
            "Explanation": "This logon type indicates the workstation was unlocked. For example, when a user presses Ctrl+Alt+Delete to unlock their computer."
        },
        "6": {
            "Description": "NetworkCleartext",
            "Explanation": "This logon type indicates a user logged on using a Cleartext password. Not recommended for security reasons."
        },
        "7": {
            "Description": "NewCredentials",
            "Explanation": "This logon type indicates a user logged on with new credentials after authenticating to a network. For example, a user accessing a resource using different credentials after initially logging on."
        },
        "8": {
            "Description": "RemoteInteractive",
            "Explanation": "This logon type indicates a remote user logged on interactively to a computer. For example, a user connecting to a computer via Remote Desktop Protocol (RDP)."
        },
        "9": {
            "Description": "CachedInteractive",
            "Explanation": "This logon type indicates a user logged on using cached credentials. For example, a user who disconnected from a network but can still log on using their cached credentials."
        },
        "10": {
            "Description": "CachedRemoteInteractive",
            "Explanation": "This logon type represents a remote user logging on interactively to a computer using cached credentials."
        },
        "11": {
            "Description": "CachedUnlock",
            "Explanation": "This logon type indicates a workstation was unlocked using cached credentials after being locked."
        }
    };

    useEffect(() => {
        const storedFilter = JSON.parse(localStorage.getItem("filters"));
        const currentPageFilter = storedFilter?.[module]?.[tab]?.fields
        const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date
        console.log("currentPageFilter", currentPageFilter, currentPageDateFilter)
        if (currentPageFilter?.length || currentPageDateFilter?.to) {
            count = currentPageFilter;
            count2 = currentPageDateFilter?.to
            setDateFilter(currentPageDateFilter)
            setFilter(currentPageFilter);

            getFilteredTable(currentPageFilter, currentPageDateFilter);
        }
    }, []);

    const groupedManagement = {
        labels: groupManagementLabel,
        datasets: [
            {
                label: "",
                data: groupManagementData,
                backgroundColor: [
                    "rgba(255, 99, 132, 0.8)",
                    "rgba(54, 162, 235, 0.8)",
                    "rgba(255, 206, 86, 0.8)",
                    "rgba(75, 192, 192, 0.8)",
                    "rgba(153, 102, 255, 0.8)",
                    "rgba(255, 159, 64, 0.8)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 0,
                hoverOffset: 0,
            },
        ],
    };

    const accountCreated = {
        labels: accoundCreatedDataLabels,
        datasets: [
            {
                label: "",
                data: accoundCreatedDataChart,
                backgroundColor: [
                    "rgba(255, 99, 132, 0.8)",
                    "rgba(54, 162, 235, 0.8)",
                    "rgba(255, 206, 86, 0.8)",
                    "rgba(75, 192, 192, 0.8)",
                    "rgba(153, 102, 255, 0.8)",
                    "rgba(255, 159, 64, 0.8)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 0,
                hoverOffset: 0,
            },
        ],
    };

    const accountDeleted = {
        labels: accoundDeletedDataLabels,
        datasets: [
            {
                label: "",
                data: accoundDeletedDataChart,
                backgroundColor: [
                    "rgba(255, 99, 132, 0.8)",
                    "rgba(54, 162, 235, 0.8)",
                    "rgba(255, 206, 86, 0.8)",
                    "rgba(75, 192, 192, 0.8)",
                    "rgba(153, 102, 255, 0.8)",
                    "rgba(255, 159, 64, 0.8)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 0,
                hoverOffset: 0,
            },
        ],
    };


    const barChartoptions = {
        responsive: true,
        // maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,

            },
        },
    };

    const donutChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            title: {
                display: true,
                // text: 'Chart.js Bar Chart',
            },
        }
    };


    const getFilteredTable = (filter, date = {}) => {
        setLoadTableData(true)
        if (filter || date?.to) {
            getActiveDirectoryTable(filter, date).then(({ data: { hits: { hits } } }) => {
                // console.log("table data :", hits)
                setActiveDirectoryTableData(hits)
                setLoadTableData(false)

                // setAccoundDeletedData(res)
            }).catch((err) => {
                setActiveDirectoryTableData([])

                setLoadTableData(false)

            })
        }
        else {
            getActiveDirectoryTable().then(({ data: { hits: { hits } } }) => {
                // console.log("table data :", hits)
                setActiveDirectoryTableData(hits)
                setLoadTableData(false)

                // setAccoundDeletedData(res)
            }).catch((err) => {
                setActiveDirectoryTableData([])
                setLoadTableData(false)

            })
        }

    }

    useEffect(() => {
        setLoadTableData(true)
        setAuthFailLoader(true)
        setAuthSuccessLoader(true)
        setAccCreatedLoader(true)
        setAccDeletedLoader(true)
        setGrpManagementActionLoader(true)
        setFileAddedLoader(true)
        setFileAccessedLoader(true)
        setFileModifiedLoader(true)
        setFileDeletedLoader(true)
        getCountsAD('winlog.event_data.SubjectUserName', filter, dateFilter).then((res) => {
            // console.log("user counts :", unique_users)
            let data = res?.data?.aggregations?.unique_users
            setUserCount(data?.value)
        }).catch((err) => {
            console.log("Error", err)
        })

        getCountsAD('winlog.computer_name', filter, dateFilter).then((res) => {
            let data = res?.data?.aggregations?.unique_users
            // console.log("host counts :", unique_users.value)
            setHostCount(data?.value)
        }).catch((err) => {
            console.log("Error", err)
        })

        // event counts
        getEventCount(filter, dateFilter).then((res) => {
            let data = res?.data?.hits?.total
            // console.log("event counts :", total.value)
            setEventCount(data.value)
        }).catch((err) => {

        })


        // account created
        getAccoundCounts("4720", filter, dateFilter).then((res) => {
            let data = res?.data?.hits?.total
            setAccountCreatedCount(data.value)
        }).catch((err) => {

        })

        // account modified
        // //   ! count zero
        // getAccoundCounts("modified-user-account").then((res) => {

        // }).catch((err) => {

        // })

        // account deleted
        getAccoundCounts("4726", filter, dateFilter).then((res) => {
            let data = res?.data?.hits?.total
            // console.log("deleted-user-account :", total.value)
            setAccountDeletedCount(data?.value)
        }).catch((err) => {

        })

        // auth user fail bar chart 
        getAuthUserChart("4625", filter, dateFilter).then(({ data: { aggregations: { 1: { buckets } } } }) => {
            // console.log("logon-faile :", buckets)
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setAuthFailLabel(label);
                    setAuthFailData(data);
                });
            }
            else {
                setAuthFailLabel([]);
                setAuthFailData([]);
            }
            setAuthFailLoader(false)
        }).catch((err) => {
            setAuthFailLoader(false)
        })

        // auth user suxx bar chart 
        getAuthUserChart("4624", filter, dateFilter).then(({ data: { aggregations: { 1: { buckets } } } }) => {
            // console.log("logged-in :", buckets)
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setAuthSuccessLabel(label);
                    setAuthSuccessData(data);
                });
            }
            else {
                setAuthSuccessLabel([]);
                setAuthSuccessData([]);
            }
            setAuthSuccessLoader(false)
        }).catch((err) => {
            console.log("Error", err)
            setAuthSuccessLoader(false)
        })

        // user management action 
        getUserManagementAction(filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            // console.log("user manangement ac :", buckets)

            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setUserManagementLabel(label);
                    setUserManagementData(data);
                });
            }
            // setUserManagement(buckets)
        }).catch((err) => {

        })

        // group management action 
        getGroupManagementAction(filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            // console.log("user mannn :", buckets)
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setGroupManagementLabel(label);
                    setGroupManagementData(data);
                });
            }
            else {
                setGroupManagementLabel([]);
                setGroupManagementData([]);
            }
            setGrpManagementActionLoader(false)
            // setGroupManagement(buckets)
        }).catch((err) => {
            setGrpManagementActionLoader(false)
        })


        // Accound created Datas and deleted

        getAccoundDatas("4720", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            // console.log("getAccoundDatas :", buckets)
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setAccoundCreatedDataLabels(label);
                    setAccoundCreatedDataChart(data);
                    //  console.log("getAccoundDatas :", item.doc_count)
                    // item.doc_count && setAccountCreatedCount(item.doc_count)

                });

            }
            else {
                setAccoundCreatedDataLabels([]);
                setAccoundCreatedDataChart([]);
            }
            setAccCreatedLoader(false)
        }).catch((err) => {
            setAccCreatedLoader(false)
        })


        // Accound deleted
        getAccoundDatas("4726", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            // console.log("deleted :", buckets)
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setAccoundDeletedDataLabels(label);
                    setAccoundDeletedDataChart(data);
                    // item.doc_count && setAccountDeletedCount(item.doc_count)
                });

            } else {
                setAccoundDeletedDataLabels([]);
                setAccoundDeletedDataChart([]);
            }
            setAccDeletedLoader(false)
        }).catch((err) => {
            setAccDeletedLoader(false)
        })

        // getActiveDirectoryTable data 

        // setCurrentPageCount((page - 1) * perPage + perPage);
        getActiveDirectoryTable(filter, dateFilter, 100, "desc", (page - 1) * perPage).then(({ data: { hits } }) => {
            setTotalPageCount(hits.total.value)
            // console.log("table data :", hits)
            setActiveDirectoryTableData(hits.hits)
            setLoadTableData(false)
            // setAccoundDeletedData(res)
        }).catch((err) => {
            setActiveDirectoryTableData([])
            setLoadTableData(false)

        })
        // if (!count?.length && !count2) {
        //     getActiveDirectoryTable().then(({ data: { hits: { hits } } }) => {
        //         setActiveDirectoryTableData(false)
        //     }).catch((err) => {
        //         console.log("Error", err)
        //     })
        // }


        // auth user suxx bar chart 
        getFileActivity("5140", filter, dateFilter).then(({ data: { aggregations: { 1: { buckets } } } }) => {
            // console.log("logged-in :", buckets)
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);

                });
                setFileAccessedLabel(label);
                setFileAccessedData(data);

            }
            else {
                setFileAccessedLabel([]);
                setFileAccessedData([]);
            }
            setFileAccessedLoader(false)

        }).catch((err) => {

        })
        // auth user suxx bar chart 
        getFileActivity("5142", filter, dateFilter).then(({ data: { aggregations: { 1: { buckets } } } }) => {
            // console.log("logged-in :", buckets)
            let label = [];
            let data = [];

            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);

                });
                setFileAddedLabel(label);
                setFileAddedData(data);

            }
            else {
                setFileAddedLabel([]);
                setFileAddedData([]);
            }
            setFileAddedLoader(false)

        }).catch((err) => {

        })
        // auth user suxx bar chart 
        getFileActivity("5143", filter, dateFilter).then(({ data: { aggregations: { 1: { buckets } } } }) => {
            // console.log("logged-in :", buckets)
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);

                });
                setFileModifiedLabel(label);
                setFileModifiedData(data);
            }
            else {
                setFileModifiedLabel([]);
                setFileModifiedData([]);
            }
            setFileModifiedLoader(false)
        }).catch((err) => {

        })
        // auth user suxx bar chart 
        getFileActivity("5144", filter, dateFilter).then(({ data: { aggregations: { 1: { buckets } } } }) => {
            // console.log("logged-in :", buckets)
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);

                });
                setFileDeletedLabel(label);
                setFileDeletedData(data);
            }
            else {
                setFileDeletedLabel([]);
                setFileDeletedData([]);
            }
            setFileDeletedLoader(false)


        }).catch((err) => {

        })

    }, [page, perPage, filter, dateFilter])


    const DoughnutClick = (event, ref, data, field, label) => {
        var activeBars = getElementAtEvent(ref.current, event);
        if (activeBars.length > 0) {
            var clickedDatasetIndex = activeBars[0].datasetIndex;
            var clickedElementIndex = activeBars[0].index;
            var clickedLabel = data.labels[clickedElementIndex];
            console.log('Clicked label:', clickedLabel);
            if (module && tab && field && label) {
                var temp = {
                    column: { label: label, value: field },
                    type: "is",
                    selectedValue: clickedLabel,
                    from: "",
                    to: "",
                    isinline: false
                }
                let storedFilter = JSON.parse(localStorage.getItem("filters"));
                let temp2 = {
                    ...storedFilter,
                    [module]: {
                        [tab]: {
                            fields: [temp]
                        }
                    }
                };
                localStorage.setItem("filters", JSON.stringify(temp2));
                setFilter([...filter, temp])
            }
        }
    }

    function handleTabSelect(key) {
        if (key === "table")
            setIsTableTabSelected(true)
        else
            setIsTableTabSelected(false)
    }
    const WindowsLoginEventCodes = {
        "4624": {
            "Description": "Successful account logon",
            "Explanation": "This event is generated when a user successfully logs on to a computer or network."
        },
        "4625": {
            "Description": "Failed account logon",
            "Explanation": "This event is generated when a user's logon attempt fails, often due to incorrect credentials or permission issues."
        },
        "4634": {
            "Description": "An account was logged off",
            "Explanation": "This event is generated when a user logs off from a computer or network."
        },
        "4648": {
            "Description": "A logon was attempted using explicit credentials",
            "Explanation": "This event is generated when a user attempts to log in using explicit credentials, usually for remote access."
        },
        "4768": {
            "Description": "A Kerberos authentication ticket (TGT) request was received",
            "Explanation": "This event is generated when a user requests a Ticket Granting Ticket (TGT) from the Key Distribution Center (KDC) in a Kerberos authentication scenario."
        },
        "4672": {
            "Description": "Special privileges assigned to new logon",
            "Explanation": "This event is generated when a user logs in, and special privileges are assigned to the new logon session."
        },
        "4776": {
            "Description": "The computer attempted to validate credentials",
            "Explanation": "This event is generated when a computer attempts to validate the credentials of a user."
        },
        "4778": {
            "Description": "A session was reconnected to a Window Station",
            "Explanation": "This event is generated when a user reconnects to a session on a Window Station."
        },
        "5140": {
            "Description": "A network share object was accessed",
            "Explanation": "This event is generated when a user or application accesses a network share object, such as a file or folder."
        }
    };
    const handleCheckboxChange = (e) => {
        let { value, checked } = e.target;

        if (checked) {
            setCheckedItems([...checkedItems, { text: value, value: value }]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item.value !== value));
        }

        let index = manageDatas.findIndex(x => x.value === value)
        manageDatas[index].isSelected = !manageDatas[index].isSelected
        setManageDatas(manageDatas)
        localStorage.setItem("ManageColumn.investigate.cykit.ad", JSON.stringify(manageDatas))
    }

    return (
        <div className="col-12 position-relative">
            <div
                className="d-flex flex-row align-items-center justify-content-end ml-auto col-5"
                style={styles.filterWrap}
            >

                <div className="position-relative">
                    <Button
                        className="mx-2"
                        ref={ref}
                        onClick={() => { setShowAddFilter(!showAddFilter); setShowManageColumn(false) }}
                    >
                        Add filter
                    </Button>
                    {showAddFilter && (
                        <AddFilter
                            filter={filter}
                            setFilter={setFilter}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            setShowAddFilter={setShowAddFilter}
                            getFilteredTable={getFilteredTable}
                            setPage={setPage}

                            columnValues={[
                                { value: "host.name", label: "Host Name", isWildCard: true },
                                { value: "event.action", label: "Event Action", isWildCard: true },
                                { value: "event.code", label: "Event Code" },
                                { value: "winlog.event_data.SubjectUserName", label: "Subject Account", isWildCard: true },
                                { value: "winlog.event_data.TargetUserName", label: "Target Account", isWildCard: true },
                                { value: "winlog.event_data.RelativeTargetName", label: "Target File", isWildCard: true },
                                { value: "winlog.event_data.ShareName", label: "Share", isWildCard: true },
                                { value: "event.original", label: "Raw Field", isWildCard: true },
                            ]}
                            module={module}
                            tab={tab}
                        />
                    )}
                </div>

                {isTableTabSelected && (
                    <div className="position-relative">
                        <Button
                            variant="light"
                            // className="mx-2"
                            ref={ref}
                            onClick={() => { setShowManageColumn(!showManageColumn); setShowAddFilter(false) }}
                        >
                            Manage columns
                        </Button>
                        {showManageColumn && (
                            <div
                                className="more-table-field-container shadow bg-white rounded-3 p-3 mt-3 ignore-onclickoutside"
                                style={{
                                    position: "absolute",
                                    right: 0,
                                    zIndex: "111",
                                    minWidth: "20rem",
                                    maxHeight: "50vh",
                                    overflow: "auto"
                                }}
                            >
                                <div className="table-fields">
                                    {manageDatas?.map((item) => (
                                        <div className="d-flex align-items-center py-2 gap-2">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={item.value}
                                                    onChange={handleCheckboxChange}
                                                    checked={item.isSelected ? true : false}
                                                // checked={investigationTableHead.some((head) => head.value.includes(item.value))}
                                                />
                                                <label className="form-check-label" for="flexCheckDefault">
                                                    {item.text}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                )}


                {isTableTabSelected && (
                    <div className="position-relative ms-2">
                        <CykitINVExport tabledata={getActiveDirectoryTable} type={"activeDirectory"} filter={filter} dateFilter={dateFilter} columns={manageDatas} />
                    </div>
                )}



                {isTableTabSelected && (
                    <Pagination
                        page={page}
                        setPage={setPage}
                        perPage={perPage}
                        currentPageCount={currentPageCount}
                        totalDataCounts={totalPageCount}
                    />
                )}

            </div>

            <div className="col-12 d-flex flex-column p-4 rounded-3 bg-white position-relative">

                <Tabs
                    defaultActiveKey="dashboard"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    onSelect={handleTabSelect}
                // onSelect={(e) => setActiveTab(e)}
                >
                    <Tab eventKey="dashboard" title="Overview">
                        <div className="col-12 d-flex flex-column flex-fill p-0 rounded-3 bg-white">
                            <FilterList
                                filter={filter}
                                setFilter={setFilter}
                                dateFilter={dateFilter}
                                setDateFilter={setDateFilter}
                                getFilteredTable={getFilteredTable}
                                module={module}
                                tab={tab}
                            />
                            <div className="d-flex flex-column gap-3" style={styles.subSection1}>
                                <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                    <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                        <div>
                                            <h4>{userCount ? userCount : "--"}</h4>
                                            <span>No of unique Users </span>
                                        </div>
                                    </div>
                                    <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                        <div>
                                            <h4>{hostCount ? hostCount : "--"}</h4>
                                            <span>No of unique Hosts</span>
                                        </div>
                                    </div>
                                    <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                        <div>
                                            <h4>{eventCount ? eventCount : "--"}</h4>
                                            <span>Event Count</span>
                                        </div>
                                    </div>
                                    <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                        <div>
                                            <h4>{accountCreatedCount ? accountCreatedCount : "--"}</h4>
                                            <span>Accounts Created</span>
                                        </div>
                                    </div>
                                    <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                        <div>
                                            <h4>{accountDeletedCount ? accountDeletedCount : "--"}</h4>
                                            <span>Accounts Deleted</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                    <div className="col-6 p-4 rounded-3 border">
                                        <h5>Authentication Failed</h5>
                                        {authFailLoader ? (<PlainCardLoader width="200px" />) : (
                                            <div className='col-12'>
                                                {authFailLabel.length || authFailData.length !== 0 ? (
                                                    <BarGraph options={barChartoptions} ChartLabel={authFailLabel} ChartData={authFailData} module={module} tab={tab} field={"winlog.event_data.TargetUserName"} label={"Target Account"} setfilter={setFilter} filter={filter} />
                                                ) : (
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                        <NoDataFound errorText={"No data found"} />
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                    </div>

                                    <div className="flex-fill p-4 rounded-3 border" >
                                        <h5>Authentication Success</h5>
                                        {authSuccessLoader ? (<PlainCardLoader width="200px" />) : (
                                            <div className='col-12'>
                                                {authSuccessLabel.length || authSuccessData.length !== 0 ? (
                                                    <BarGraph options={barChartoptions} ChartLabel={authSuccessLabel} ChartData={authSuccessData} module={module} tab={tab} field={"winlog.event_data.TargetUserName"} label={"Target Account"} setfilter={setFilter} filter={filter} />
                                                ) : (
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                        <NoDataFound errorText={"No data found"} />
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                    </div>
                                </div>

                                {/* <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                    <div className="col-6 p-4 rounded-3 border">
                                        <h5>User Management Actions</h5>
                                        {userManagementLabel.length || userManagementData.length !== 0 ? (
                                            <BarGraph ChartLabel={userManagementLabel} ChartData={userManagementData} module={module} tab={tab} field={"event.action"} label={"Event Action"} setfilter={setFilter} filter={filter} />
                                        ) : (
                                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                <NoDataFound errorText={"No data found"} />
                                            </div>
                                        )}
                                    </div>

                                    <div className="flex-fill p-4 rounded-3 border" >
                                        <h5>Group Management Actions</h5>
                                        {groupManagementLabel.length || groupManagementData.length !== 0 ? (
                                            <BarGraph ChartLabel={groupManagementLabel} ChartData={groupManagementData} />
                                        ) : (
                                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                <NoDataFound errorText={"No data found"} />
                                            </div>
                                        )}
                                    </div>
                                </div> */}

                                <div className="col-12 d-flex flex-column gap-3 p-0 rounded-3 bg-white">
                                    <div className='col-12 mt-4'>
                                        <h5 className='mb-0'>User Management Actions</h5>
                                    </div>
                                    <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">

                                        <div className="d-flex flex-column col-4 p-4 rounded-3 border" >
                                            <h5>Accounts Created by</h5>
                                            {accCreatedLoader ? (<PlainCardLoader width="200px" />) : (
                                                <div className='col-12'>
                                                    {accoundCreatedDataLabels.length || accoundCreatedDataChart.length !== 0 ? (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "350px", }}>
                                                            <Doughnut data={accountCreated} options={donutChartOptions} ref={DoughnutCreatedchartRef} onClick={(event) => { DoughnutClick(event, DoughnutCreatedchartRef, accountCreated, "winlog.event_data.SubjectUserName", "Subject Account") }} />
                                                        </div>
                                                    ) : (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                            <NoDataFound errorText={"No data found"} />
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                        </div>

                                        <div className="d-flex flex-column col-4 p-4 rounded-3 border" >
                                            <h5>Accounts Deleted by</h5>
                                            {accDeletedLoader ? (<PlainCardLoader width="200px" />) : (
                                                <div className='col-12'>
                                                    {accoundCreatedDataLabels.length || accoundCreatedDataChart.length !== 0 ? (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "350px", }}>
                                                            <Doughnut className='' data={accountDeleted} options={donutChartOptions} ref={DoughnutDeletedchartRef} onClick={(event) => { DoughnutClick(event, DoughnutDeletedchartRef, accountDeleted, "winlog.event_data.SubjectUserName", "Subject Account") }} />
                                                        </div>
                                                    ) : (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                            <NoDataFound errorText={"No data found"} />
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                        </div>

                                        <div className="d-flex flex-column flex-fill p-4 rounded-3 border" >
                                            <h5>Group Management Actions</h5>
                                            {grpManagementActionLoader ? (<PlainCardLoader width="200px" />) : (
                                                <div className='col-12'>
                                                    {groupManagementLabel.length || groupManagementData.length !== 0 ? (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "350px", }}>
                                                            <Doughnut data={groupedManagement} options={donutChartOptions} ref={DoughnutGroupchartRef} onClick={(event) => { DoughnutClick(event, DoughnutGroupchartRef, groupedManagement, "event.action", "Event action") }} />
                                                        </div>
                                                    ) : (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                            <NoDataFound errorText={"No data found"} />
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>


                                <div className="col-12 d-flex flex-column gap-3 p-0 rounded-3 bg-white">
                                    <div className='col-12 mt-4'>
                                        <h5 className='mb-0'>File Share Actions</h5>
                                    </div>

                                    <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                        {/* <div className="d-flex flex-column col-4 p-4 rounded-3 border" >
                                            <h5>Group Management Actions</h5>
                                            {groupManagementLabel.length || groupManagementData.length !== 0 ? (
                                                <Doughnut data={groupedManagement} ref={DoughnutGroupchartRef} onClick={(event) => { DoughnutClick(event, DoughnutGroupchartRef, groupedManagement, "event.action", "Event action") }} />
                                            ) : (
                                                <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                    <NoDataFound errorText={"No data found"} />
                                                </div>
                                            )}
                                        </div> */}

                                        <div className="d-flex flex-column col-6 p-4 rounded-3 border" >
                                            <h5>File Added</h5>
                                            {fileAddedLoader ? (<PlainCardLoader width="200px" />) : (
                                                <div className='col-12'>
                                                    {fileAddedLabel.length || fileAddedData.length !== 0 ? (
                                                        <div className='col-12'>
                                                            <BarGraph ChartLabel={fileAddedLabel} ChartData={fileAddedData} module={module} tab={tab} field={"host.name"} label={"Host"} setfilter={setFilter} filter={filter} />
                                                        </div>
                                                    ) : (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                            <NoDataFound errorText={"No data found"} />
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                        </div>

                                        <div className="d-flex flex-column flex-fill p-4 rounded-3 border" >
                                            <h5>File Accessed</h5>
                                            {fileAccessedLoader ? (<PlainCardLoader width="200px" />) : (
                                                <div className='col-12'>
                                                    {fileAccessedLabel.length || fileAccessedData.length !== 0 ? (
                                                        <div className='col-12'>
                                                            <BarGraph ChartLabel={fileAccessedLabel} ChartData={fileAccessedData} module={module} tab={tab} field={"host.name"} label={"Host"} setfilter={setFilter} filter={filter} />
                                                        </div>
                                                    ) : (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                            <NoDataFound errorText={"No data found"} />
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                    <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                        {/* <div className="d-flex flex-column col-4 p-4 rounded-3 border" >
                                            <h5>Group Management Actions</h5>
                                            {groupManagementLabel.length || groupManagementData.length !== 0 ? (
                                                <Doughnut data={groupedManagement} ref={DoughnutGroupchartRef} onClick={(event) => { DoughnutClick(event, DoughnutGroupchartRef, groupedManagement, "event.action", "Event action") }} />
                                            ) : (
                                                <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                    <NoDataFound errorText={"No data found"} />
                                                </div>
                                            )}
                                        </div> */}

                                        <div className="d-flex flex-column col-6 p-4 rounded-3 border" >
                                            <h5>File Modified</h5>
                                            {fileModifiedLoader ? (<PlainCardLoader width="200px" />) : (
                                                <div className='col-12'>
                                                    {fileModifiedLabel.length || fileModifiedData.length !== 0 ? (
                                                        <div className='col-12'>
                                                            <BarGraph ChartLabel={fileModifiedLabel} ChartData={fileModifiedData} module={module} tab={tab} field={"host.name"} label={"Host"} setfilter={setFilter} filter={filter} />
                                                        </div>
                                                    ) : (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                            <NoDataFound errorText={"No data found"} />
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                        </div>

                                        <div className="d-flex flex-column flex-fill p-4 rounded-3 border" >
                                            <h5>File Deleted</h5>
                                            {fileDeletedLoader ? (<PlainCardLoader width="200px" />) : (
                                                <div className='col-12'>
                                                    {fileDeletedLabel.length || fileDeletedData.length !== 0 ? (
                                                        <div className='col-12'>
                                                            <BarGraph ChartLabel={fileDeletedLabel} ChartData={fileDeletedData} module={module} tab={tab} field={"host.name"} label={"Host"} setfilter={setFilter} filter={filter} />
                                                        </div>
                                                    ) : (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                            <NoDataFound errorText={"No data found"} />
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>


                                {/* <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                    <div className="col-6 p-4 rounded-3 border" >
                                        <h5>Logged Users</h5>
                                        {loggedUserLabel.length || loggedUserData.length !== 0 ? (
                                            <BarGraph ChartLabel={loggedUserLabel} ChartData={loggedUserData} ChartTitle={"Logged Users"} />
                                        ) : (
                                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                <NoDataFound errorText={"No data found"} />
                                            </div>
                                        )}
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="table" title="Details">
                        <div className="col-12">
                            <div className="" style={styles.tableWrap}>
                                <FilterList
                                    filter={filter}
                                    setFilter={setFilter}
                                    dateFilter={dateFilter}
                                    setDateFilter={setDateFilter}
                                    getFilteredTable={getFilteredTable}
                                    module={module}
                                    tab={tab}
                                />
                                {loadTableData ? (
                                    <TableLoader rowCount={30} />
                                ) : (
                                    activeDirectoryTableData.length !== 0 ? (
                                        <Table borderless hover>
                                            <thead className="fixed-head">
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    {manageDatas.map((item) => (
                                                        item.isSelected === true &&
                                                        <th>{item.text}</th>
                                                    ))}
                                                    {/* <th>Host Name</th>
                                                    <th>Event Action</th>
                                                    <th>Event Code</th>
                                                    <th>Subject Account</th>
                                                    <th>Target Account</th>
                                                    <th>Share</th>
                                                    <th>Target File</th>
                                                    */}
                                                    <th> </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {activeDirectoryTableData?.map((item, index) => (
                                                    <tr
                                                        onMouseOver={() =>
                                                            setShowInlineFilter({ show: true, index: index })
                                                        }
                                                        onMouseOut={() =>
                                                            setShowInlineFilter({ show: false, index: index })
                                                        }>
                                                        <td className='font-monospace'>{getDateFromTimestamp(item._source?.["@timestamp"])}</td>
                                                        <td className='font-monospace'>{getTimeFromTimestamp(item._source?.["@timestamp"])}</td>
                                                        {manageDatas?.map((data) => (
                                                            data.value === "host.name" || data.value === "winlog.computer_name" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="">
                                                                        {data.value === "host.name" ? item?._source?.host?.name : item?._source?.winlog.computer_name}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: data.value === "host.name" ? "host.name" : "winlog.computer_name",
                                                                                            label: "Host Name",
                                                                                        }}
                                                                                        value={data.value === "host.name" ? item?._source?.host.name : item?._source?.winlog.computer_name}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "event.action" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="font-monospace">
                                                                        {item?._source.event.action}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "event.action",
                                                                                            label: "Event Action",
                                                                                        }}
                                                                                        value={item?._source.event.action}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "event.code" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="font-monospace">
                                                                        <CustomToolTip content={<span>{item._source.event.code}</span>} dataPlacement={"bottom"}
                                                                            dataToggle={
                                                                                <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                                                                    {item._source?.winlog?.event_data?.LogonType &&
                                                                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                                                                            <span>Logon type - </span>
                                                                                            <span><strong>{item._source.winlog?.event_data?.LogonType + " (" + LogonTypes[item._source.winlog?.event_data?.LogonType].Description + ")"}</strong></span>
                                                                                        </div>
                                                                                    }

                                                                                    {item._source.winlog?.event_data?.LogonType &&
                                                                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                                                                            <span>Explanation - </span>
                                                                                            <span><strong>{LogonTypes[item._source.winlog?.event_data?.LogonType].Explanation}</strong></span>
                                                                                        </div>
                                                                                    }

                                                                                    {(item._source?.event?.code && WindowsLoginEventCodes[item._source?.event?.code]) &&
                                                                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                                                                            <span>Event code - </span>
                                                                                            <span><strong>{item._source?.event?.code + " (" + WindowsLoginEventCodes[item._source?.event?.code]?.Explanation + ")"}</strong></span>
                                                                                        </div>
                                                                                    }

                                                                                </div>
                                                                            } >
                                                                            {item?._source.event.code}
                                                                        </CustomToolTip>

                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "event.code",
                                                                                            label: "Event Code",
                                                                                        }}
                                                                                        value={item?._source.event.code}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "winlog.event_data.SubjectUserName" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="font-monospace">
                                                                        {item?._source.winlog.event_data?.SubjectUserName}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "winlog.event_data.SubjectUserName",
                                                                                            label: "Subject Account",
                                                                                        }}
                                                                                        value={item?._source.winlog.event_data?.SubjectUserName}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "winlog.event_data.TargetUserName" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="font-monospace">
                                                                        {item?._source.winlog.event_data?.TargetUserName}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "winlog.event_data.TargetUserName",
                                                                                            label: "Target Account",
                                                                                        }}
                                                                                        value={item?._source.winlog.event_data?.TargetUserName}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "winlog.event_data.ShareName" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="font-monospace">
                                                                        {item?._source.winlog.event_data?.ShareName}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "winlog.event_data.ShareName",
                                                                                            label: "Share Name",
                                                                                        }}
                                                                                        value={item?._source.winlog.event_data?.ShareName}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "winlog.event_data.RelativeTargetName" && data.isSelected === true ? (
                                                                <td className='col-3'>
                                                                    <span className="font-monospace">
                                                                        {item?._source.winlog.event_data?.RelativeTargetName}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "winlog.event_data.RelativeTargetName",
                                                                                            label: "Target File",
                                                                                        }}
                                                                                        value={item?._source.winlog.event_data?.RelativeTargetName}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "message" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="font-monospace">
                                                                        <CustomToolTip content={<span>{(item._source.message.split('.', 1)[0])}</span>} dataPlacement={"bottom"} style={{ "width": "500px" }}
                                                                            dataToggle={
                                                                                <div style={styles.tooltipWrap}>
                                                                                    {item?._source.message}
                                                                                </div>
                                                                            }>

                                                                        </CustomToolTip>
                                                                        {/* </OverlayTrigger> */}

                                                                    </span>
                                                                </td>) : (<></>)
                                                        ))}


                                                        {/* <td>{item?._source.winlog.event_data.ShareName}</td> */}
                                                        <td>
                                                            <a onClick={() => { setExpClickedNode(item._id); setExpClose(false); setDoc(item) }} style={{ marginLeft: "20px" }}>
                                                                {/* < size={18} style={{ color: "#666666" }} /> */}
                                                                <TableViewMore Icon={ArrowsOutSimple} dataToggle={"Expand document"} dataPlacement={"bottom"} />
                                                                {/* <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M19 3c-1.654 0-3 1.346-3 3 0 .502.136.968.354 1.385l-1.116 1.302A3.976 3.976 0 0 0 13 8c-.739 0-1.425.216-2.02.566L9.566 7.152A3.449 3.449 0 0 0 10 5.5C10 3.57 8.43 2 6.5 2S3 3.57 3 5.5 4.57 9 6.5 9c.601 0 1.158-.166 1.652-.434L9.566 9.98A3.972 3.972 0 0 0 9 12c0 .997.38 1.899.985 2.601l-1.692 1.692.025.025A2.962 2.962 0 0 0 7 16c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3c0-.476-.121-.919-.318-1.318l.025.025 1.954-1.954c.421.15.867.247 1.339.247 2.206 0 4-1.794 4-4a3.96 3.96 0 0 0-.439-1.785l1.253-1.462c.364.158.764.247 1.186.247 1.654 0 3-1.346 3-3s-1.346-3-3-3zM7 20a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM5 5.5C5 4.673 5.673 4 6.5 4S8 4.673 8 5.5 7.327 7 6.5 7 5 6.327 5 5.5zm8 8.5c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm6-7a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path></svg> */}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </Table>
                                    ) : (
                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                            <NoDataFound errorText={"No data found"} />
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <ExpandedDocument doc={doc} setDoc={setDoc} clickedNode={expclickedNode} close={expclose} setClick={setExpClickedNode} url={datalakeURL} pattern={`${localStorage.getItem("INVESTIGATE_CYAD")}/_search?track_total_hits=true`} req={fpostDl} />

        </div>
    )
}

export default CyActiveDirectory